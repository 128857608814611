import api from "./index"

/**
 * Get all posts
 *
 * @param params 
 * @param includes
 * @return {*}
 */
export const getParserPublishPostsRequest = (params, includes = []) => {
  return api
    .request("admin/parser/posts/publish")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all Need to approve posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getParserNeedApprovePostsRequest = (params, includes = []) => {
  return api
    .request(`admin/parser/${params.resource}/post-list`)
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all Declined posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getParserDeclinedPostsRequest = (params, includes = []) => {
  return api
      .request(`admin/parser/${params.resource}/post-list`)
      .withParams(params)
      .withIncludes(includes)
      .get()
}


/**
 * Get all archive posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getParserArchivePostsRequest = (params, includes = []) => {
  return api
    .request("admin/parser/posts/archive")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all reported posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getParserReportedPostsRequest = (params, includes = []) => {
  return api
    .request("admin/parser/posts/reported")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Get all removal posts
 *
 * @param params
 * @param includes
 * @return {*}
 */
export const getParserRemovalPostsRequest = (params, includes = []) => {
  return api
    .request("admin/parser/posts/removal")
    .withParams(params)
    .withIncludes(includes)
    .get()
}

export const bulkActions = (params) => {
  return api
      .request('admin/parser/posts/bulkActions')
      .withHeaders({
        "Content-type": "multipart/form-data"
      })
      .withBody(params, true)
      .post()
}