<template>
  <div class="pb-3">
    <v-layout row>
      <v-flex xs3>
        <v-text-field
          v-model="search"
          hide-details 
          solo
          label="Search post"
          append-icon="search"
          @input="searchInput"
        />
      </v-flex>
    </v-layout>

    <v-layout row justify-space-between>
      <v-flex xs12>
        <v-btn-toggle class="table-top-menu">
          <v-btn flat large :to="{ name: 'parser_need_approve_posts', params: { name: $route.params.name } }">
            Need to approve
            <span
              v-if="newPostsCount"
              class="count-new-posts"
              v-text="newPostsCount"
            >
            </span>
          </v-btn>

          <v-btn flat large :to="{ name: 'parser_decline_posts', params: { name: $route.params.name } }">
            Declined
          </v-btn>

          <v-btn flat large :to="{ name: 'parser_posts', params: { name: $route.params.name } }">
            All active posts
          </v-btn>
          

          <v-btn flat large :to="{ name: 'parser_archive_posts', params: { name: $route.params.name } }">
            Archive
          </v-btn>

          <v-btn flat large :to="{ name: 'parser_reported_posts', params: { name: $route.params.name } }">
            Reported
          </v-btn>

          <v-btn flat large :to="{ name: 'parser_removal_posts', params: { name: $route.params.name } }">
            Removal request
          </v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ParserNav",
  data: () => ({
    inputSearchPostTimer: null,
    newPostsCount: null,
    search: null
  }),
  created() {
    this.$bus.on("new-posts-count", data => {
      this.newPostsCount = data
    })
  },
  destroyed() {
    this.$bus.off("new-users-count")
  },
  methods: {
    searchInput() {
      clearTimeout(this.inputSearchPostTimer)
      this.inputSearchPostTimer = setTimeout(() => {
        this.$bus.emit("parsed-post-search-input", this.search)
      }, 2000)
    }
  }
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
.count-new-posts {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #f44336;
  border-radius: 50%;
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
  transform: translateY(-50%);
}
</style>
